<template>
  <figure
    class="image"
    :class="[
      {
        'image--loaded': !loading,
        'image--error': error
      }
    ]"
  >
    <NuxtPicture v-bind="$attrs" @load="onload" @error="onerror" />
  </figure>
</template>

<script lang="ts">
export default {
  name: 'ImagePicture',
  props: {
    background: {
      type: String,
      default: ''
    }
  },
  setup() {
    const loading = ref(true)
    const error = ref(false)
    const onload = () => {
      loading.value = false
    }

    const onerror = () => {
      loading.value = false
      error.value = true
    }

    return {
      loading,
      error,
      onload,
      onerror
    }
  }
}
</script>
